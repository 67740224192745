<template>
    <div class="workDetail">
      <Nav></Nav>
      <li class="con" v-for="(item, index) in list" :key="index">
        <div class="img">
          <img :src="item.url" alt="" :style="{marginTop:(index == 0 ? '0px' : '20px')}" />
        </div>
        <div class="yangMiddle">
            <img src="@/assets/yangMiddle.jpg" />
        </div>
        <div class="frame">
            <!-- <div class="frameCon">
                <img src="@/assets/yangImg1.jpg" alt="">
                <div class="price">
                    <div class="left">TMK单人桌代采</div>
                    <div class="right">￥</div>
                </div>
            </div> -->
            <div class="frameCon" v-for="(item,index) in productList" :key="index">
                <div class="frameCon_img" @click="jumpDetail(index + 1)">
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="price">
                    <div class="left">{{item.title}}</div>
                    <div class="right">￥{{item.price}}</div>
                </div>
                <div class="button" @click="codeShow(index)">采购</div>
                <img src="@/assets/yangCode.jpg" alt="" class="productCodeImg" v-show="codeShowNum == index">
            </div>
        </div>
      </li>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
  import Nav from "../components/Nav";
  import Footer from '../components/FooterInside.vue'
  export default {
    components: {
      Nav,
      Footer
    },
    data() {
      return {
        list: [
          {
            url: require("../assets/yangBanner.jpg"),
          }

        ],
        productList:[
        {
                title: 'TMK单人桌代采',
                price: 500,
                imgUrl: require('@/assets/yangImg1.jpg')
            },
            {
                title: '漾 中式主人椅',
                price: 800,
                imgUrl: require('@/assets/yangImg2.jpg')
            },
            {
                title: '漾 黑胡桃单人沙发',
                price: 830,
                imgUrl: require('@/assets/yangImg3.jpg')
            },
            {
                title: '漾X彦木 实木单人沙发',
                price: 2800,
                imgUrl: require('@/assets/yangImg4.jpg')
            },
        ],
        codeShowNum: 999
      };
    },
    methods: {
        codeShow(index) {
            if(index === this.codeShowNum) {
                this.codeShowNum = 999
            } else {
                this.codeShowNum = index
            }
        },
        jumpDetail(index) {
          this.$router.push({
            path: '/yangDetail',
            query: {
              id: index
            }
          })
        }
    }
  };
  </script>
  
  <style scoped lang="less">
  .workDetail {
    .yangMiddle{
        width: 100%;
        text-align: center;
        margin: 3vw 0;
        img{
            width: 80%;;
        }
    }
    .frame{
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 100px;
        padding: 0 16px;
        &::after{
          content: '';
          width: 330px;
        }
        .frameCon{
          &_img{
            cursor: pointer;
          }
            margin-top: 2vh;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 330px;
            img{
                height: 330px;
                width: 100%;   
            }
            .price{
                display: flex;
                justify-content: space-between;
                font-size: 1.5vw;
            }
            .button{
                cursor: pointer;
                margin-top: 0.5vw;
                background: #000;
                color: #fff;
                padding: .3vw;
                font-size: 1vw;
                text-align: center
            }
            .productCodeImg{
                position: absolute;
                height: 230px;
                width: 230px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -70%);
            }
          
        }
        
    }
    .con {
      width: 100%;
     
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        img {
  
          width: 100%;
        }
  
        img[lazy="loading"] {
          width: 10%;
        }
      }
      
  
      .text {
        display: flex;
        justify-content: space-between;
        padding: 0.3rem;
  
        .left {
          width: 850px;
  
          .title {
            font-size: 26px;
            margin-bottom: 30px;
          }
  
          .chinese {
            font-size: 15px;
            line-height: 0px;
            margin-bottom: 0px;
            font-weight: 400;
          }
  
          .chinese-min {
            font-size: 15px;
            line-height: 28px;
            font-weight: 320;
            span{font-weight: 500;}
          }
  
          .english {
            font-size: 15px;
            line-height: 22px;
            font-weight: 320;
          }
        }
  
        .right {
          margin-top: 64px;
          margin-right: 0.3rem;
          font-size: 14px;
  
          span {
            display: inline-block;
            width: 130px;
          }
  
          p {
            line-height: 12px;
          }
        }
      }
    }
  
  }
  
  /* 宽度大于1900*/
  @media only screen and (min-width:1900px) {
  
    .text {
      .left {
        width: 950px !important;
  
        .title {
          font-size: 30px !important;
        }
  
        .chinese {
          font-size: 16px !important;
          line-height: 18px !important;
        }
  
        .chinese-min {
          font-size: 17px !important;
          line-height: 30px !important;
        }
  
        .english {
          font-size: 15px !important;
          line-height: 22px !important;
        }
      }
  
      .right {
        margin-top: 68px !important;
        margin-right: 0.3rem !important;
        font-size: 16px !important;
  
        span {
          display: inline-block;
          width: 140px;
        }
  
        p {
          line-height: 12px;
        }
      }
    }
  
  
  }
  
  /* 屏幕宽度大于1600并且小于1899 */
  @media only screen and (min-width:1600px) and (max-width:1899px) {
  
  
    .text {
      .left {
        width: 900px !important;
  
        .title {
          font-size: 27px !important;
          margin-bottom: 20px;
        }
  
        .chinese {
          font-size: 16px !important;
          line-height: 28px !important;
        }
  
        .chinese-min {
          font-size: 16px !important;
          line-height: 28px !important;
        }
  
        .english {
          font-size: 14px !important;
          line-height: 22px !important;
        }
      }
  
      .right {
        margin-top: 65px !important;
        margin-right: 0.3rem !important;
        font-size: 14px !important;
  
        span {
          display: inline-block;
          width: 120px;
        }
  
        p {
          line-height: 12px;
        }
      }
    }
  
  }
  
  /* 宽度大于1100并且小于1400 */
  @media only screen and (min-width:1100px) and (max-width:1399px) {
  
    .text {
      .left {
        width: 680px !important;
  
        .title {
          font-size: 24px !important;
          margin-bottom: 20px;
        }
  
        .chinese {
          font-size: 25px !important;
          line-height: 0px;
          margin-bottom: 0px;
        }
  
        .chinese-min {
          font-size: 14px !important;
          line-height: 24px !important;
          font-weight: 320;
        }
  
        .english {
          font-size: 14px !important;
          line-height: 22px !important;
          font-weight: 320;
        }
      }
  
      .right {
        margin-top: 45px !important;
        margin-right: 0.3rem !important;
        font-size: 13px !important;
  
        span {
          display: inline-block;
          width: 120px;
        }
  
        p {
          line-height: 12px;
        }
      }
    }
  }
  
  /* 宽度大于768并且小于1100 */
  @media only screen and (min-width:768px) and (max-width:1099px) {
    .text {
      .left {
        width: 500px !important;
  
        .title {
          font-size: 24px !important;
          margin-bottom: 18px;
        }
  
        .chinese {
          font-size: 25px !important;
          line-height: 0px;
          margin-bottom: 0px;
        }
  
        .chinese-min {
          font-size: 12px !important;
          line-height: 24px !important;
          font-weight: 320;
        }
  
        .english {
          font-size: 12px !important;
          line-height: 22px !important;
          font-weight: 320;
        }
      }
  
      .right {
        margin-top: 45px !important;
        margin-right: 0.3rem !important;
        font-size: 12px !important;
  
        span {
          display: inline-block;
          width: 130px;
        }
  
        p {
          line-height: 10px;
        }
      }
    }
  }
  
  /* 宽度小于768 */
  @media only screen and (max-width:768px) {
    .workDetail {
      .con {
        .img {
          width: 100%;
        }
  
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 30px 0;
  
          .left {
            width: 96%;
  
            .title {
              margin-left: 0px;
              font-size: 22px;
              margin-bottom: 20px;
            }
  
            .chinese-min {
              font-size: 15px !important;
              line-height: 26px !important;
            }
  
            .english {
              font-size: 15px;
              line-height: 20px !important;
            }
          }
  
          .right {
            width: 96%;
            padding: 0 !important;
            margin: 0 !important;
            padding-left: 0 !important;
            font-size: 13px;
  
            p {
              line-height: 10px;
            }
          }
        }
      }
    }
  }
  </style>